@font-face {
  font-family: "carter";
  src: url("../public/font/carter.ttf");
}

* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1e6091;
  font-family: "carter";
}

.hud {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 3;
  pointer-events: none;
}

.loading {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controllers {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.controllers div {
  margin: 2rem;
  pointer-events: all;
}

.jump {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background: #fff3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jump img {
  filter: invert(1);
  width: 50%;
  pointer-events: none;
}

.reset {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #fff3;
  position: absolute;
  right: 0;
  bottom: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset img {
  filter: invert(1);
  width: 50%;
  pointer-events: none;
}

.mobile {
  display: none;
}

.sign_tag {
  /* background-color: #fcd29c; */
  background-color: white;
  border-radius: 100px;
  white-space: nowrap;
  padding: 8px 12px;
}

@media only screen and (max-width: 660px) {
  .mobile {
    display: flex;
  }
}
